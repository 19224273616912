.navigation {
  height: 15vh;
  width: 95vw;
  display: flex;
  justify-self: center;
  justify-content: space-between;

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; 
    width: 10vw;
    .logo{ 
      height:100%;
      width: fit-content;
    }
  }

  .nav-links-container {
    width: 21%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav-link {
      padding: 15px 15px;
      cursor: pointer;
    }
  }
}
.sign-in-link{
  padding: 5px;
}
.nav-title {
  text-align: center;
  align-self: center;
  justify-self:end;
  width: 100%;
  height: 100%;
  font-family: 'Source Serif 4', serif;
  font-weight:bolder;
  font-size: 20px;
  padding: 0px 0px 0px 20px ;
  h2{ 
    margin-bottom: 0;
  }
  p{ 
    margin-top: 0;
  }
}

