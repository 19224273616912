body {
  margin: 0;
  padding: 10px 20px;
  padding-bottom: 0px;
  font-family: 'Source Serif 4', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Serif 4', serif;
}

a {
  text-decoration: none;
  color: black ;
}
* {
  box-sizing: border-box;
}
