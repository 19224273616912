.authentication-container {
  display: flex;
  width: 900px;
  justify-content: space-between;
  margin: 10px auto;
}
.signInPageTitle{
  display: flex;
  flex-direction: column;
  align-items: center;

}
